import React, { useState, useEffect } from 'react'
import Box from '@material-ui/core/Box'
import BootstrapTable from 'react-bootstrap-table-next'
import filterFactory, { textFilter, selectFilter, numberFilter } from 'react-bootstrap-table2-filter'
import cellEditFactory from 'react-bootstrap-table2-editor'
import { getProductString } from 'config/helpers/Helpers'

const fields = {
    volume: 0, 
    revenue: 0,
    facebook: 0, 
    google: 0, 
    tiktok: 0, 
    reddit: 0,
    bing: 0,
    ghl: 0
}

function ProfitabilityDetailsTable(props) {
    let arr = props.product === 'Financial' ? [
        {product: 'life-insurance', ...fields},
        {product: 'income-protection', ...fields},
        {product: 'pensions', ...fields},
        {product: 'mortgage-protection', ...fields},
        {product: 'health-insurance', ...fields}
    ] : [
        {product: 'Solar', ...fields}
    ]

    if (props.details) {
        for (const [product, values] of Object.entries(props.details)) {
            console.log(product)
            console.log(values)
            for (let item of arr) {
                if (item.product === product) {
                    if (values.hasOwnProperty('volume')) {
                        item.volume = values.volume || 0
                        item.revenue = values.revenue || 0
                        item.facebook = values.facebook || 0
                        item.google = values.google || 0
                        item.tiktok = values.tiktok || 0
                        item.reddit = values.reddit || 0
                        item.bing = values.bing || 0
                        item.ghl = values.ghl || 0
                    }
                    else {
                        item.volume = 0
                        item.revenue = 0
                        item.facebook = 0
                        item.google = 0
                        item.tiktok = 0
                        item.reddit = 0
                        item.bing = 0
                        item.ghl = 0
                    }
                }
            }
        }
    }

    const [details, setDetails] = useState(arr)

    let columns = [
        {
            dataField: 'product',
            text: 'Product',
            sort: true,
            editable: false,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return <>{props.product === 'Solar' ? 'Solar' : getProductString(row.product)}</>
            },
            footer: 'Total'
        },
        {
            dataField: 'volume',
            text: 'Volume',
            sort: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{row.volume}</>
                )
            },
            footer: details.map(item => item.volume).reduce((accumulator, current) => accumulator + current, 0).toFixed()
        },
        {
            dataField: 'facebook',
            text: 'FB',
            sort: true,
            editable: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{row.facebook}</>
                )
            },
            footer: details.map(item => item.facebook).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)
        },
        {
            dataField: 'google',
            text: 'Google',
            sort: true,
            editable: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{row.google}</>
                )
            },
            footer: details.map(item => item.google).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)
        },
        {
            dataField: 'tiktok',
            text: 'Tik Tok',
            sort: true,
            editable: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{row.tiktok}</>
                )
            },
            footer: details.map(item => item.tiktok).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)
        },
        {
            dataField: 'reddit',
            text: 'Reddit',
            sort: true,
            editable: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{row.reddit}</>
                )
            },
            footer: details.map(item => item.reddit).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)
        },
        {
            dataField: 'bing',
            text: 'Bing',
            sort: true,
            editable: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{row.bing}</>
                )
            },
            footer: details.map(item => item.bing).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)
        },
        {
            dataField: 'ghl',
            text: 'GHL',
            sort: true,
            editable: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{row.ghl}</>
                )
            },
            footer: details.map(item => item.ghl).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)
        },
        {
            dataField: 'cost',
            text: 'Cost',
            sort: true,
            editable: false,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{(row.facebook + row.google + row.tiktok + row.reddit + row.bing + row.ghl).toFixed(2)}</>
                )
            },
            footer: getTotalCost()
        },
        {
            dataField: 'revenue',
            text: 'Revenue',
            sort: true,
            editable: true,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{row.revenue}</>
                )
            },
            footer: details.map(item => item.revenue).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)
        },
        {
            dataField: 'profit',
            text: 'Profit',
            sort: true,
            editable: false,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{(row.revenue - (row.facebook + row.google + row.tiktok + row.reddit + row.bing + row.ghl)).toFixed(2)}</>
                )
            },
            footer: details.map(item => (item.revenue - (item.facebook + item.google + item.tiktok + item.reddit + item.bing + item.ghl))).reduce((accumulator, current) => accumulator + current, 0).toFixed(2)
        },
        {
            dataField: 'margin',
            text: 'Margin',
            sort: true,
            editable: false,
            //filter: numberFilter(),
            formatter: (cell, row) => {
                return (
                    <>{row.revenue > 0 ? (((row.revenue - (row.facebook + row.google + row.tiktok + row.reddit + row.bing + row.ghl))/row.revenue) * 100).toFixed(2) + '%' : 0}</>
                )
            },
            footer: getTotalMargin()
        }
    ]

    function getTotalCost() {
        let cost = details.map(item => {
            //console.log(item.facebook)
            //console.log(item.google)
            //console.log(item.tiktok)
            return (item.facebook + item.google + item.tiktok + item.reddit + item.bing + item.ghl)
        }).reduce((accumulator, current) => accumulator + current, 0)
        console.log('total cost: ', cost)
        return cost.toFixed(2)
    }

    function getTotalMargin() {
        let revenue = details.map(item => item.revenue).reduce((accumulator, current) => accumulator + current, 0)
        let profit = details.map(item => (item.revenue - (item.facebook + item.google + item.tiktok + item.reddit + item.bing + item.ghl))).reduce((accumulator, current) => accumulator + current, 0)
        let margin = 0
        if (revenue > 0) {
            margin = (profit/revenue) * 100
        }
        return margin.toFixed(2) + '%'
    }

    const handleTableChange = (type, { data, cellEdit: { rowId, dataField, newValue } }) => {
        console.log(rowId)
        console.log(dataField)
        console.log('new value: ', newValue)

        // update state to new field
        const result = data.map((row) => {
            console.log(row)
            if (row.product === rowId) {
                const newRow = { ...row }
                // parsing so totals still work after cell update
                newRow[dataField] = parseFloat(newValue)
                return newRow
            }
            return row
        })
        console.log('table change result: ', result)
        updateDetails(result)
    }

    function updateDetails(updatedDetails) {
        console.log(updatedDetails)
        setDetails(updatedDetails)
        // convert to key value object and update in parent
        let obj = {}
        for (let item of updatedDetails) {
            obj[item.product] = {
                volume: parseInt(item.volume),
                revenue: parseFloat(item.revenue),
                facebook: parseFloat(item.facebook),
                google: parseFloat(item.google),
                tiktok: parseFloat(item.tiktok),
                reddit: parseFloat(item.reddit),
                bing: parseFloat(item.bing),
                ghl: parseFloat(item.ghl)
            }
        }
        console.log(obj)
        props.updateDetails(obj)
    }

    return (
        <div className='table-container'>
            <BootstrapTable 
                keyField='product' 
                data={ details } 
                columns={ columns } 
                filter={ filterFactory() } 
                noDataIndication={<Box>No data</Box>}
                cellEdit={ cellEditFactory({ mode: 'click', blurToSave: true }) }
                onTableChange={ handleTableChange }
                remote={ {
                    filter: false,
                    pagination: false,
                    sort: false,
                    cellEdit: true
                } }
            />
        </div>
    );
}

export default ProfitabilityDetailsTable